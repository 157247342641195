/* src/components/about/Team.css */

.team {
  padding: 80px 20px;
  background: url('/src/assets/images/imagesss.jpg') no-repeat center center fixed; /* Add your background image path */
  background-size: cover;
  color: #2D3142;
  animation: fadeIn 1s ease-out;
}

.team .container {
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.team-headline {
  font-size: 42px;
  margin-bottom: 40px;
  color: #2D3142;
  letter-spacing: 2px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.team-overview {
  font-size: 18px;
  line-height: 1.8;
  color: #2D3142;
  font-family: 'Roboto', sans-serif;
}

.team-overview p {
  margin-bottom: 30px;
}

.roles-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.role-item {
  perspective: 1000px;
  margin: 15px;
  width: calc(45% - 30px);
  animation: fadeInUp 0.8s ease both;
  z-index: 1;
  position: relative;
  transition: z-index 0.6s;
}

.role-item.flipped {
  z-index: 999;
}

.role-item .inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s, height 0.6s;
  transform-style: preserve-3d;
}

.role-item:hover .inner, .role-item.flipped .inner {
  transform: rotateY(180deg);
  height: 300px; /* Increase the height on hover/flip */
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
}

.front {
  background: #2D3142; /* Adjust color as per theme */
  color: #fff; /* Adjust text color */
  border: 1px solid #6EC1E4;
}

.back {
  background: #444;
  color: #fff;
  transform: rotateY(180deg);
}

.role-item strong {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  color: #fff;
  text-align: left; /* Align titles to the left */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .role-item {
    width: calc(100% - 30px); /* Adjust width for smaller screens */
  }

  .role-item .inner {
    display: flex;
    align-items: center;
  }

  .role-item .inner .back {
    display: none; /* Hide the back content on small screens */
  }

  .role-item .front {
    text-align: left; /* Align titles to the left */
    color: #000; /* Change title color for visibility on small screens */
    background: none; /* Remove background color */
    border: none; /* Remove border */
    width: 100%; /* Ensure full width for the title */
    padding: 10px 0; /* Adjust padding for smaller screens */
  }
}

@media (min-width: 769px) {
  .role-item .front {
    text-align: left; /* Align titles to the left */
  }
}
