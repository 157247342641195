/* src/components/contact/OfficeLocations.css */

.office-locations {
  padding: 60px 20px;
  background-color: transparent;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}

.office-locations .container {
  width: 100%;
  max-width: 1200px;
  padding: 40px;
  text-align: center;
}

.locations-headline {
  font-size: 48px;
  margin-bottom: 40px;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
}

.locations-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.location-item {
  background-color: rgba(45, 49, 66, 0.9); /* Semi-transparent background */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 300px; /* Fixed width to ensure uniform size */
  height: 300px; /* Fixed height to ensure uniform size */
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.location-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.location-city {
  font-size: 24px;
  margin-bottom: 10px;
  color: #FFD700;
}

.location-address, .location-contact {
  font-size: 16px;
  margin-bottom: 10px;
  color: #FFFFFF;
}

.location-map-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 16px;
  color: #FFD700;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.location-map-link:hover {
  color: #FFFFFF;
}

/* Responsive styling */
@media (max-width: 768px) {
  .locations-list {
    flex-direction: column;
    align-items: center;
  }

  .location-item {
    width: 80%; /* Slightly smaller on medium screens */
    height: auto; /* Auto height on smaller screens */
  }
}

@media (max-width: 480px) {
  .locations-list {
    gap: 10px;
  }

  .location-item {
    width: 100%; /* Full width on smaller screens */
    padding: 15px;
  }

  .location-city {
    font-size: 20px; /* Slightly smaller text */
  }

  .location-address, .location-contact {
    font-size: 14px; /* Slightly smaller text */
  }

  .location-map-link {
    font-size: 14px; /* Slightly smaller text */
  }
}
