/* src/components/solutions/CombinedSolutions.css */

.combined-solutions {
  padding: 60px 20px;
  background-color: #F0F0F0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solution-section {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.solution-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .combined-solutions {
    padding: 40px 10px;
  }

  .solution-section {
    margin: 20px 0;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .combined-solutions {
    padding: 30px 10px;
  }

  .solution-section {
    margin: 15px 0;
    padding: 10px;
  }
}
