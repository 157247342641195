/* src/components/academy/CareerDevelopment.css */

.career-development {
    padding: 50px 0;
    background-color: #f9f9f9;
    color: #2D3142;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .headline {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #2D3142;
  }
  
  .content-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .content-section {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #2D3142;
  }
  
  .section-content {
    font-size: 1rem;
    color: #555;
  }
  
  .section-content-edit {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .edit-button {
    margin-top: 10px;
    padding: 10px 20px;
    background: #2D3142;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .edit-button:hover {
    background: #FFD700;
    color: #2D3142;
  }
  
  @media (max-width: 768px) {
    .section-content {
      font-size: 0.9rem;
    }
  
    .section-title {
      font-size: 1.3rem;
    }
  
    .headline {
      font-size: 2rem;
    }
  }
  