/* src/components/blog/CompanyNews.css */

.company-news {
  padding: 60px 20px;
  background-color: #FFFFFF;
  color: #2D3142;
}

.company-news .container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.news-headline {
  font-size: 36px;
  margin-bottom: 40px;
}

.news-list {
  margin-bottom: 40px;
}

.news-item {
  background-color: #F0F0F0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.news-item.expanded {
  max-width: 100%;
}

.news-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.news-date {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.news-content {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.read-more-button {
  background-color: #FFD700;
  color: #2D3142;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin: 10px auto 0;
}

.read-more-button:hover {
  background-color: #FFFFFF;
  color: #2D3142;
}

.explore-more-link {
  display: block;
  margin: 10px auto 0;
  color: #2D3142;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.news-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.edit-button,
.delete-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button {
  background-color: #FF6347;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #FFD700;
  color: #2D3142;
}

.news-form {
  margin-top: 40px;
  text-align: left;
}

.form-headline {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FFD700;
}
