/* src/components/clientPortal/LoginSignUp.css */

.login-signup {
    padding: 60px 20px;
    background-color: #FFFFFF;
    color: #2D3142;
  }
  
  .login-signup .container {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  
  .form-headline {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .thank-you-message {
    font-size: 24px;
    color: #2D3142;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submit-button {
    background-color: #2D3142;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background-color: #FFD700;
  }
  
  .toggle-button {
    background-color: transparent;
    color: #2D3142;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .toggle-button:hover {
    text-decoration: underline;
  }
  