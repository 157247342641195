/* src/components/search/SearchBar.css */

.search-bar {
    padding: 20px;
    background-color: #FFFFFF;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .search-input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  