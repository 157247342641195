/* src/components/homepage/Testimonials.css */

.testimonials {
    padding: 60px 0;
    background-color: #FFFFFF;
    color: #2D3142;
  }
  
  .testimonials .container {
    text-align: center;
  }
  
  .testimonials-headline {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .testimonials-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 40px;
  }
  
  .testimonial-item {
    background-color: #F0F0F0;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    max-width: 300px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-message {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .testimonial-author {
    font-size: 14px;
    color: #555;
  }
  
  .testimonial-form {
    margin-top: 40px;
  }
  
  .form-headline {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 150px;
  }
  
  .submit-button {
    background-color: #2D3142;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #FFD700;
  }
  