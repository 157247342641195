/* src/components/contact/ContactForm.css */

.contact-form {
  padding: 60px 20px;
  background: linear-gradient(135deg, #c3c5c5, #cececf); /* New attractive background */
  color: #2D3142;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}

.contact-form .container {
  width: 100%;
  max-width: 800px;
  padding: 40px;
  animation: fadeIn 1s ease-in-out;
}

.contact-headline {
  font-size: 48px;
  margin-bottom: 40px;
  color: #2D3142;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.7);
  text-align: center;
  width: 100%;
}

.thank-you-message {
  font-size: 24px;
  color: #2D3142;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
}

.contact-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.form-group {
  width: 100%;
  text-align: left;
  animation: fadeIn 1s ease-in-out;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #2D3142;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #2D3142;
  border-radius: 4px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.9);
  color: #2D3142;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #FFD700;
}

.form-group textarea {
  resize: vertical;
  height: 200px;
}

.submit-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #FFD700;
  color: #2D3142;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styling */
@media (max-width: 768px) {
  .contact-form .container {
    padding: 30px;
  }

  .contact-headline {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .form-group input,
  .form-group textarea {
    padding: 12px;
  }

  .submit-button {
    padding: 12px 24px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .contact-form .container {
    padding: 20px;
  }

  .contact-headline {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .form-group input,
  .form-group textarea {
    padding: 10px;
  }

  .submit-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
