/* src/components/products/SupportDocumentation.css */

.support-documentation {
  padding: 60px 20px;
  background-color: #f5f5f5;
  color: #2d3142;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support-documentation .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.docs-headline {
  font-size: 48px;
  margin-bottom: 40px;
  color: #2d3142;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.docs-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.doc-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 280px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.doc-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.doc-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2d3142;
}

.doc-type {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.doc-link {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.doc-link:hover {
  color: #FFD700;
}

.message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message-box {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 80%;
}

.message-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #2d3142;
}

.message-content {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

.message-close-button {
  padding: 10px 20px;
  background-color: #2d3142;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.message-close-button:hover {
  background-color: #FFD700;
}

@media (max-width: 768px) {
  .docs-headline {
    font-size: 36px;
  }

  .doc-item {
    width: 90%;
  }

  .message-box {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .docs-headline {
    font-size: 28px;
  }

  .doc-item {
    width: 100%;
  }

  .message-box {
    width: 95%;
  }
}
