/* src/components/chat/ChatBot.css */

.chatbot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #FFD700;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    z-index: 1000; /* Ensure it floats on top of all sections */
  }
  
  .chatbot:hover {
    transform: scale(1.1);
  }
  
  .chatbot-icon {
    color: #2D3142;
    font-size: 30px;
  }
  
  .notification {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  