/* src/components/blog/EducationalContent.css */

.educational-content {
  padding: 60px 20px;
  background-color: transparent;
  color: #2D3142;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.educational-content .container {
  max-width: 1200px;
  text-align: center;
}

.content-headline {
  font-size: 48px;
  margin-bottom: 40px;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.content-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  min-width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  cursor: pointer;
}

.content-item.expanded {
  min-width: 100%;
}

.content-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.content-date {
  font-size: 16px;
  margin-bottom: 10px;
}

.content-text {
  font-size: 16px;
  margin-bottom: 10px;
}

.read-more-button {
  background-color: #FFD700;
  color: #2D3142;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin: 10px auto 0;
}

.read-more-button:hover {
  background-color: #FFFFFF;
  color: #2D3142;
}

.explore-more-link {
  display: block;
  margin: 10px auto 0;
  color: #2D3142;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.content-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.edit-button,
.delete-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button {
  background-color: #FF6347;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #FFD700;
  color: #2D3142;
}

.content-form {
  margin-top: 40px;
  text-align: left;
}

.form-headline {
  font-size: 24px;
  margin-bottom: 20px;
  color: #FFD700;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #FFFFFF;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  background-color: #FFD700;
  color: #2D3142;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FFFFFF;
  color: #2D3142;
}

/* Responsive styling */
@media (max-width: 768px) {
  .content-list {
    flex-direction: column;
    align-items: center;
  }

  .content-item {
    max-width: 100%;
    min-width: 100%;
  }

  .read-more-button {
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .content-item {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
  }
}
