/* src/components/products/ProductList.css */

.product-list {
  padding: 60px 20px;
  background-color: #FFFFFF;
  color: #2D3142;
}

.product-list .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.products-headline {
  font-size: 36px;
  margin-bottom: 40px;
  color: #2D3142;
}

.products {
  display: flex;
  overflow-x: auto; /* Horizontal scroll */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
  scroll-behavior: smooth;
}

.product-item {
  background-color: #F0F0F0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  min-width: 300px;
  max-width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.product-name {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2D3142;
}

.product-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.product-features {
  list-style: none;
  padding: 0;
}

.product-feature {
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

/* Responsive styling */
@media (max-width: 768px) {
  .product-list {
    padding: 40px 10px;
  }

  .products-headline {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .product-item {
    min-width: 90%;
    max-width: 90%;
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .product-list {
    padding: 30px 5px;
  }

  .products-headline {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .product-item {
    min-width: 100%;
    max-width: 100%;
    margin: 10px auto;
  }
}
