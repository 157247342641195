.careers {
  padding: 60px 20px;
  background-color: #FFFFFF;
  color: #2D3142;
}

.careers .container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.careers-headline {
  font-size: 36px;
  margin-bottom: 40px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.job-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.job-item {
  background-color: #F0F0F0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  max-width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.job-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.job-location {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.job-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.apply-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.apply-button:hover {
  background-color: #FFD700;
}

.application-form {
  margin-top: 40px;
  text-align: left;
}

.form-headline {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FFD700;
}
