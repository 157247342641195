/* src/components/interactive/LiveChat.css */

.live-chat {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 500px;
  }
  
  .chat-header {
    padding: 10px;
    background-color: #2D3142;
    color: #fff;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    max-width: 80%;
  }
  
  .chat-message.user {
    background-color: #e1f5fe;
    align-self: flex-end;
  }
  
  .chat-message.support {
    background-color: #f1f1f1;
    align-self: flex-start;
  }
  
  .chat-input-form {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  
  .chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .send-button {
    background-color: #2D3142;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #FFD700;
  }
  