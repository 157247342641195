/* src/components/services/ServiceDetails.css */

.service-details {
  padding: 60px 20px;
  background: url('/src/assets/images/imagesss.jpg') no-repeat center center fixed; /* Add background image */
  background-size: cover; /* Ensure the background covers the entire section */
  color: #2D3142;
  position: relative;
}

.service-details::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6); /* Transparent white overlay */
  z-index: 1;
}

.service-details .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
}

.services-headline {
  font-size: 36px;
  margin-bottom: 40px;
  color: #2D3142;
}

.services-list {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping of service items */
  justify-content: center; /* Center items on larger screens */
  margin-bottom: 40px;
}

.service-item {
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent plate */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  max-width: 300px;
  text-align: left;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  flex-grow: 1; /* Allow items to grow and fill the container */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-item img.service-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.service-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2D3142;
}

.service-description {
  font-size: 16px;
  color: #2D3142;
}

.service-detail {
  text-align: left;
  margin-top: 40px;
}

.service-detail-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #2D3142;
}

.service-detail-description {
  font-size: 16px;
  line-height: 1.6;
  color: #2D3142;
  margin-bottom: 20px;
}

.back-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.back-button:hover {
  background-color: #FFD700;
}

.cta {
  margin-top: 40px;
  font-size: 18px;
  color: #2D3142;
}

.cta a {
  color: #FFD700;
  text-decoration: none;
  font-weight: bold;
}

.cta a:hover {
  color: #FFB800;
}

/* Responsive styling */
@media (max-width: 768px) {
  .service-item {
    max-width: 100%; /* Ensure items take full width on smaller screens */
    margin: 10px 0; /* Adjust margin for better spacing on mobile */
  }

  .service-detail {
    margin-top: 20px;
  }

  .service-detail-title {
    font-size: 24px;
  }

  .service-detail-description {
    font-size: 14px;
  }

  .cta {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .service-item {
    max-width: 100%; /* Full width on very small screens */
  }

  .service-detail-title {
    font-size: 20px;
  }

  .service-detail-description {
    font-size: 12px;
  }

  .cta {
    font-size: 14px;
  }
}