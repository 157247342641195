/* src/components/academy/StudentDashboard.css */

.student-dashboard {
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
}

.sidebar {
  width: 250px;
  background-color: #2D3142;
  color: #fff;
  padding: 20px;
  overflow-y: auto;
  position: fixed;
  height: 100%;
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #FFD700;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.3s;
}

.sidebar ul li a:hover {
  background: #FFD700;
  color: #2D3142;
}

.sidebar ul li a svg {
  margin-right: 10px;
}

.sidebar .submenu {
  display: none;
  list-style: none;
  padding-left: 20px;
}

.sidebar .submenu.active {
  display: block;
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
}

.section {
  display: none;
}

.section.active {
  display: block;
}

.top-right {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.notifications {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.notifications .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.profile {
  cursor: pointer;
}

.badge {
  background: #FFD700;
  color: #2D3142;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}
