/* src/components/resources/WebinarsEvents.css */

.webinars-events {
  padding: 60px 20px;
  background-color: #FFFFFF;
  color: #2D3142;
}

.webinars-events .container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.events-headline {
  font-size: 36px;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in;
  text-align: center;
}

.sub-headline {
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #2D3142;
  animation: fadeIn 1s ease-in;
}

.upcoming-headline,
.past-headline {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
}

.events-list {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-item {
  background-color: #F0F0F0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  position: relative;
  animation: slideIn 1s ease-in-out;
  transform: translateZ(-50px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.event-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 110%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: -1;
  transform: translate(-50%, -50%) scale(0.8);
}

.event-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.event-date {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.event-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.read-more-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.read-more-button:hover {
  background-color: #FFD700;
}

.register-link {
  display: block;
  margin-top: 10px;
  color: blue;
  text-decoration: none;
  font-weight: bold;
  animation: fadeIn 1s ease-in;
}

.register-link:hover {
  color: #FFD700;
}

.event-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.edit-button,
.delete-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button {
  background-color: #FF6347;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #FFD700;
}

.event-form {
  margin-top: 40px;
  text-align: left;
}

.form-headline {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FFD700;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive styling */
@media (max-width: 768px) {
  .webinars-events .container {
    padding: 0 10px;
  }

  .event-item {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .event-item {
    padding: 10px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 6px;
  }

  .submit-button {
    padding: 6px 12px;
  }
}

/* Media query for larger screens */
@media (min-width: 1024px) {
  .upcoming-headline,
  .past-headline {
    text-align: left;
    margin-left: 0;
  }
}
