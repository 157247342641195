/* src/components/academy/Enrollments.css */

.enrollments {
    background: url('/src/assets/images/coding.avif') no-repeat center center fixed;
    background-size: cover;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .enrollments-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
  }
  
  .enrollments-container h2 {
    font-size: 2.5rem;
    color: #2D3142;
    margin-bottom: 20px;
  }
  
  .enrollments-container p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .login-link {
    display: inline-block;
    padding: 10px 20px;
    background: #2D3142;
    color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .login-link:hover {
    background: #FFD700;
    color: #2D3142;
  }
  