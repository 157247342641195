/* src/components/products/CombinedProducts.css */

.combined-products {
  background: url('/src/assets/images/coding.avif') no-repeat center center fixed;
  background-size: cover;
  padding: 60px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.combined-products-headline {
  font-size: 48px;
  margin-bottom: 40px;
  text-align: center;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.product-section {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 30px;
  margin: 20px 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 95%; /* Increased width */
  max-width: 1200px; /* Increased max-width */
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product-section h3 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #2D3142;
}

.product-section p {
  font-size: 16px;
  color: #555;
}

@media (max-width: 768px) {
  .combined-products {
    padding: 40px 10px;
  }

  .product-section {
    margin: 15px 0;
    padding: 20px;
  }

  .combined-products-headline {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .combined-products {
    padding: 30px 5px;
  }

  .product-section {
    margin: 10px 0;
    padding: 15px;
  }

  .combined-products-headline {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
