/* src/components/solutions/IndustrySolutions.css */

.industry-solutions {
  padding: 60px 20px;
  background-color: #F0F0F0;
  color: #2D3142;
}

.industry-solutions .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.industry-headline {
  font-size: 36px;
  margin-bottom: 40px;
  color: #2D3142;
}

.industry-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.industry-item {
  background-color: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 45%;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  max-width: 45%;
}

.industry-item.expanded {
  transform: scale(1.05);
  z-index: 10;
}

.industry-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2D3142;
}

.industry-description {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.industry-solutions-list {
  list-style: none;
  padding: 0;
}

.industry-solution {
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #2D3142;
}

.industry-solution a {
  color: #2D3142;
  text-decoration: none;
}

.industry-solution a:hover {
  color: #FFD700;
  text-decoration: underline;
}

.read-more {
  background: none;
  border: none;
  color: #FFD700;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  padding: 0;
  margin-top: 10px;
  display: block;
  text-align: right;
}

.read-more:hover {
  color: #FFB800;
}

/* Responsive styling */
@media (max-width: 768px) {
  .industry-item {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .industry-title {
    font-size: 22px;
  }

  .industry-description {
    font-size: 14px;
  }

  .industry-solution {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .industry-title {
    font-size: 20px;
  }

  .industry-description {
    font-size: 12px;
  }

  .industry-solution {
    font-size: 12px;
  }
}
