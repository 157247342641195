/* src/components/academy/HarmosoftAcademy.css */
.harmosoft-academy {
    background: url('/src/assets/images/coding.avif') no-repeat center center fixed;
    background-size: cover;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .harmosoft-academy section {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 30px;
    margin: 20px 0;
    width: 100%;
    max-width: 900px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .harmosoft-academy section:hover {
    transform: scale(1.02);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  