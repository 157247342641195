/* src/components/blog/BlogPost.css */

.blog-post {
  padding: 60px 20px;
  background-color: transparent;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.blog-post .container {
  max-width: 1200px;
  text-align: center;
}

.blog-headline {
  font-size: 48px;
  margin-bottom: 40px;
  color: #FFFFFF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.carousel {
  position: relative;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FFD700;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

.post-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 20px;
  margin-bottom: 40px;
}

.post-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  min-width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  cursor: pointer;
}

.post-item.expanded {
  min-width: 100%;
}

.post-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.post-date {
  font-size: 16px;
  margin-bottom: 10px;
}

.post-content {
  font-size: 16px;
  margin-bottom: 10px;
}

.read-more-button {
  background-color: #FFD700;
  color: #2D3142;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  margin: 10px auto 0;
}

.read-more-button:hover {
  background-color: #FFFFFF;
  color: #2D3142;
}

.explore-more-link {
  display: block;
  margin: 10px auto 0;
  color: #FFFFFF;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.post-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.edit-button,
.delete-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button {
  background-color: #FF6347;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #FFD700;
  color: #2D3142;
}

.post-form {
  margin-top: 40px;
  text-align: left;
}

.form-headline {
  font-size: 24px;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #FFFFFF;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  background-color: #FFD700;
  color: #2D3142;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FFFFFF;
  color: #2D3142;
}

.scroll-bar {
  overflow-x: auto;
  overflow-y: hidden;
  margin: 10px 0;
  height: 20px;
}

.scroll-bar-content {
  width: 1000px; /* Adjust this width according to your content */
  height: 1px; /* Just to make the div visible for scrolling */
}

/* Responsive styling */
@media (max-width: 768px) {
  .post-list {
    flex-direction: column;
    align-items: center;
  }

  .post-item {
    max-width: 100%;
    min-width: 100%;
  }

  .read-more-button {
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .post-item {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
  }
}
