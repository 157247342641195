.footer {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  margin: 20px 0;
  flex: 1;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 16px; /* Reduced font size */
  color: #FFD700;
}

.footer-section ul {
  list-style: disc; /* Set list style to disc */
  padding-left: 20px; /* Add padding to left for bullets */
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
  font-size: 14px; /* Reduced font size */
}

.footer-section a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 14px; /* Reduced font size */
}

.footer-section a:hover {
  color: #FFD700;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.newsletter-form input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.newsletter-form button {
  padding: 10px 20px;
  background-color: #FFD700;
  color: #2D3142;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.newsletter-form button:hover {
  background-color: #ffcc00;
}

.footer-copyright {
  margin-top: 20px;
  font-size: 12px; /* Reduced font size */
  color: #2D3142;
  text-align: center;
  width: 100%;
  background-color: #FFFFFF;
  padding: 10px 0;
}

/* Responsive styling */
@media (max-width: 768px) {
  .footer-section {
    min-width: 100%;
    text-align: center;
  }

  .footer-section ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to start */
    padding-left: 0; /* Remove padding on smaller screens */
  }

  .footer-section ul li {
    margin-left: 20px; /* Add margin to left for bullets */
  }

  .newsletter-form {
    align-items: center;
    width: 100%;
  }

  .newsletter-form input {
    max-width: 100%;
  }

  .newsletter-form button {
    width: 100%;
    text-align: center;
  }
}
