/* src/components/testimonials/SuccessStories.css */

.success-stories {
    padding: 60px 20px;
    background-color: #FFFFFF;
    color: #2D3142;
  }
  
  .success-stories .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .stories-headline {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .stories-list {
    margin-bottom: 40px;
  }
  
  .story-item {
    background-color: #F0F0F0;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .story-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .story-client,
  .story-date,
  .story-description {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .story-outcomes {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .story-outcomes li {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .story-actions {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .delete-button {
    background-color: #2D3142;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .delete-button {
    background-color: #FF6347;
  }
  
  .edit-button:hover,
  .delete-button:hover {
    background-color: #FFD700;
  }
  
  .story-form {
    margin-top: 40px;
    text-align: left;
  }
  
  .form-headline {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 150px;
  }
  
  .submit-button {
    background-color: #2D3142;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #FFD700;
  }
  