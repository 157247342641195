/* src/components/legal/TermsOfService.css */

.terms-of-service {
    padding: 60px 20px;
    background-color: #FFFFFF;
    color: #2D3142;
  }
  
  .terms-of-service .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .terms-headline {
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .terms-content h3 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-content p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .terms-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .terms-content ul li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
  }
  