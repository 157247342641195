/* src/components/resources/Whitepapers.css */

.whitepapers {
  padding: 60px 20px;
  background: url('/src/assets/images/plants.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #2D3142;
  position: relative;
}

.whitepapers::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

.whitepapers .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
}

.whitepapers-headline {
  font-size: 48px;
  margin-bottom: 40px;
  color: whitesmoke;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.carousel-button {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.carousel-button:hover {
  background-color: #FFD700;
  transform: scale(1.1);
}

.whitepapers-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  padding: 10px 0;
  margin-bottom: 40px;
  scroll-behavior: smooth;
}

.whitepaper-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  min-width: 300px;
  max-width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  flex: 0 0 auto;
}

.whitepaper-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.whitepaper-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.whitepaper-date {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.whitepaper-link {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #2D3142;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.whitepaper-link:hover {
  color: #FFD700;
}

.whitepaper-actions {
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button {
  background-color: #FF6347;
}

.edit-button:hover,
.delete-button:hover {
  background-color: #FFD700;
}

.whitepaper-form {
  margin-top: 40px;
  text-align: left;
}

.form-headline {
  font-size: 24px;
  margin-bottom: 20px;
  color: whitesmoke;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #FFD700;
}

.message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message-box {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 80%;
}

.message-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #2d3142;
}

.message-content {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

.message-close-button {
  padding: 10px 20px;
  background-color: #2d3142;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.message-close-button:hover {
  background-color: #ff7f50;
}

/* Responsive styling */
@media (max-width: 768px) {
  .whitepapers {
    padding: 40px 10px;
  }

  .whitepapers-list {
    flex-direction: column;
  }

  .whitepaper-item {
    min-width: 100%;
    max-width: 100%;
  }

  .form-group input {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .whitepapers {
    padding: 30px 10px;
  }

  .form-group input {
    padding: 6px;
  }

  .submit-button {
    padding: 6px 12px;
  }
}
