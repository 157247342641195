/* src/components/products/DemoRequest.css */

.demo-request {
  padding: 60px 20px;
  background: linear-gradient(135deg, #e0f7fa, #ffffff);
  color: #2D3142;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

.demo-request::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* Transparent white overlay */
  z-index: 1;
}

.demo-request .container {
  max-width: 800px; /* Increased max-width */
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
}

.demo-headline {
  font-size: 36px;
  margin-bottom: 40px;
  animation: fadeInDown 1s ease-in;
}

.demo-form .form-group {
  margin-bottom: 20px;
  text-align: left;
  animation: fadeInUp 1s ease-in;
}

.demo-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #2D3142;
}

.demo-form input,
.demo-form select,
.demo-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.demo-form input:focus,
.demo-form select:focus,
.demo-form textarea:focus {
  border-color: #2D3142;
}

.demo-form textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  background-color: #2D3142;
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #FFD700;
}

.submit-message {
  background-color: #ffffff;
  color: #2D3142;
  border: 1px solid #ddd;
  padding: 40px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 24px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-in;
}

/* Keyframes for animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styling */
@media (max-width: 768px) {
  .demo-request {
    padding: 40px 10px;
  }

  .demo-form input,
  .demo-form select,
  .demo-form textarea {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
  }

  .demo-headline {
    font-size: 28px;
  }

  .submit-message {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .demo-request {
    padding: 30px 10px;
  }

  .demo-form input,
  .demo-form select,
  .demo-form textarea {
    padding: 6px;
  }

  .submit-button {
    padding: 6px 12px;
  }

  .demo-headline {
    font-size: 24px;
  }

  .submit-message {
    font-size: 18px;
  }
}
