.header {
  padding: 10px 20px;
  box-shadow: 0 4px 2px -2px gray;
  background-color: #2D3142;
  color: #FFFFFF;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.header.scrolling {
  background-color: rgba(45, 49, 66, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white line */
}

.home-header {
  background-color: #2D3142;
}

.other-header {
  background-color: #2D3142;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo {
  display: flex;
  align-items: center;
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.header .logo img {
  height: 30px;
  width: auto;
}

.header .logo-text {
  margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
}

.navigation {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px; /* Adjust gap between links */
  margin: 0;
  padding: 0;
}

.nav-links a {
  text-decoration: none;
  font-weight: 500;
  color: #FFFFFF;
  position: relative;
  font-size: 14px; /* Reduced font size */
}

.nav-links a:hover {
  color: #FFD700;
}

.nav-links .active {
  color: #FFD700;
}

.nav-links .active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #FFD700;
}

.dropdown {
  position: relative;
}

.dropdown-link {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #2D3142;
  list-style: none;
  padding: 10px;
  margin: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  margin: 0;
}

.dropdown-menu a {
  display: block;
  padding: 5px 10px;
  color: #FFFFFF;
  font-size: 14px; /* Reduced font size */
}

.dropdown-menu a:hover {
  background-color: #FFD700;
  color: #2D3142;
}

.menu-icon {
  display: none;
  color: #FFFFFF;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive styling */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: #2D3142;
    position: absolute;
    top: 60px;
    right: 0;
    width: 200px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
  }

  .menu-icon {
    display: block;
  }
}
