/* src/components/academy/Webinars.css */

.webinars {
    background: url('/src/assets/images/coding.avif') no-repeat center center fixed;
    background-size: cover;
    padding: 50px 0;
    color: #1a1a1a;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .headline {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #FFD700; /* Gold */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .webinar-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .webinar-item {
    background: rgba(255, 255, 255, 0.85); /* Light semi-transparent background */
    border: 2px solid #6EC1E4; /* Light blue border */
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 350px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
  }
  
  .webinar-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .webinar-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #2D3142;
  }
  
  .webinar-date {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #6EC1E4;
  }
  
  .webinar-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .toggle-details-button,
  .register-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background: #FFD700; /* Gold background */
    color: #1a1a1a;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s ease;
    margin-right: 5px;
    text-decoration: none; /* For anchor element */
  }
  
  .toggle-details-button:hover,
  .register-button:hover {
    background: #FFB700; /* Slightly darker gold */
  }
  
  .webinar-actions {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    gap: 10px;
    margin-top: 10px;
  }
  
  .edit-button,
  .delete-button {
    background: #6EC1E4; /* Light blue */
    border: none;
    padding: 10px;
    color: #1a1a1a;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .delete-button {
    background: #FF6347; /* Light red */
  }
  
  .edit-button:hover,
  .delete-button:hover {
    background: #FFD700;
    color: #1a1a1a;
  }
  
  @media (max-width: 1024px) {
    .webinar-item {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .webinar-item {
      width: 100%;
    }
  
    .webinar-list {
      flex-direction: column;
      align-items: center;
    }
  }
  