/* src/components/testimonials/ClientReviews.css */

.client-reviews {
    padding: 60px 20px;
    background-color: #FFFFFF;
    color: #2D3142;
  }
  
  .client-reviews .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .reviews-headline {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .reviews-list {
    margin-bottom: 40px;
  }
  
  .review-item {
    background-color: #F0F0F0;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .review-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .review-date {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .review-text {
    font-size: 16px;
    color: #555;
  }
  
  .review-form {
    margin-top: 40px;
    text-align: left;
  }
  
  .form-headline {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 150px;
  }
  
  .submit-button {
    background-color: #2D3142;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #FFD700;
  }
  
  .thank-you-message {
    font-size: 24px;
    color: #2D3142;
  }
  