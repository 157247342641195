/* src/components/services/CaseStudies.css */

.case-studies {
  padding: 60px 20px;
  background-color: #F0F0F0;
  color: #2D3142;
}

.case-studies .container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.case-studies-headline {
  font-size: 36px;
  margin-bottom: 40px;
  color: #2D3142;
}

.case-studies-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.case-study-item {
  background-color: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.case-study-item:hover {
  transform: scale(1.05);
  z-index: 10;
}

.case-study-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #2D3142;
}

.case-study-client {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.case-study-description {
  font-size: 16px;
  color: #555;
  flex-grow: 1;
}

.read-more {
  display: block;
  background: none;
  border: none;
  color: #FFD700;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  padding: 0;
  margin-top: 10px;
}

.read-more:hover {
  color: #FFB800;
}

.expanded .case-study-description {
  margin-bottom: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .case-study-item {
    padding: 15px;
  }

  .case-study-title {
    font-size: 22px;
  }

  .case-study-client {
    font-size: 16px;
  }

  .case-study-description {
    font-size: 14px;
  }

  .read-more {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .case-studies-headline {
    font-size: 28px;
  }

  .case-study-item {
    padding: 10px;
  }

  .case-study-title {
    font-size: 20px;
  }

  .case-study-client {
    font-size: 14px;
  }

  .case-study-description {
    font-size: 12px;
  }

  .read-more {
    font-size: 12px;
  }
}
