/* src/components/academy/AcademyContact.css */

.academy-contact {
  padding: 60px 20px;
  background-color: #f5f5f5;
  color: #2d3142;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.academy-contact .container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-headline {
  font-size: 36px;
  margin-bottom: 40px;
  color: #2d3142;
  text-align: center;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-details, .contact-form {
  flex: 1;
  margin: 20px;
  min-width: 300px;
}

.contact-details {
  font-size: 18px;
  margin-bottom: 40px;
}

.contact-details p {
  margin: 10px 0;
}

.contact-details a {
  color: #2d3142;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

.contact-form {
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #2d3142;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.submit-button {
  background-color: #2d3142;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #ffd700;
  color: #2d3142;
}

/* Responsive styling */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-details, .contact-form {
    width: 100%;
    margin: 10px 0;
  }
}
