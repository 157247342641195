/* src/components/lab/HarmosoftLab.css */

.harmosoft-lab {
  padding: 60px 20px;
  background: url('/src/assets/images/lab.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #2D3142;
}

.harmosoft-lab .container {
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.harmosoft-lab h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: left;
  color: #FFD700;
}

.harmosoft-lab p {
  font-size: 18px;
  margin-bottom: 40px;
  text-align: left;
  color: #555;
}

.harmosoft-lab h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2D3142;
}

.services-facilities {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.plate {
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid #FFD700;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.plate:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plate h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #2D3142;
}

.plate ul {
  list-style: disc;
  padding-left: 20px;
}

.plate ul li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border: 2px solid #FFD700;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin-left: 0;
}

.booking-form label {
  font-size: 16px;
  color: #2D3142;
}

.booking-form input, .booking-form select, .booking-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.booking-form button {
  padding: 10px 20px;
  background: #2D3142;
  color: #FFD700;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.booking-form button:hover {
  background: #FFD700;
  color: #2D3142;
}

@media (max-width: 1024px) {
  .plate {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .plate {
    width: 100%;
  }
}
