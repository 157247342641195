/* src/components/academy/CourseDetails.css */

.course-details {
    background: url('/src/assets/images/coding.avif') no-repeat center center fixed;
    background-size: cover;
    padding: 50px 0;
    color: #2D3142;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .headline {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #FFD700; /* Gold */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .course-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .course-item {
    background: rgba(255, 255, 255, 0.9); /* Light semi-transparent background */
    border: 2px solid #FFD700; /* Gold border */
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    text-align: left; /* Align text to the left */
  }
  
  .course-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .course-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #2D3142;
  }
  
  .course-date {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #555;
  }
  
  .course-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .toggle-details-button,
  .register-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background: #2D3142; /* Dark background */
    color: rgba(255, 255, 255, 0.9); /* Light text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s ease;
    margin-right: 5px;
  }
  
  .toggle-details-button:hover,
  .register-button:hover {
    background: #FFB700; /* Slightly darker gold */
  }
  
  @media (max-width: 1024px) {
    .course-item {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .course-item {
      width: 100%;
    }
  
    .course-list {
      flex-direction: column;
      align-items: center;
    }
  }
  